<template>
  <b-card no-body style="min-height:400px;">
    <b-card-header v-if="options.showTitle || options.showAccount" class="p-0">
      <div>
        <b-card-title v-if="options.showTitle" class="mb-1">
          {{ $t('components.cards.meli.settings.blocklists.page_title')}}
        </b-card-title>
        <b-card-sub-title v-if="options.showAccount && !account">
          <span v-if="selectedAccount">{{ selectedAccount.nickname }}</span>
          <span v-else>{{ $t('components.cards.common.select_account') }}</span>
        </b-card-sub-title>
      </div>
      <div class="d-flex align-items-center">
        <simple-account-selector v-if="!inherit && !account" @change="changeSelectedAccount"></simple-account-selector>
      </div>
    </b-card-header>

    <b-card-body v-if="!showMass" class="p-0">
      <b-row>
        <b-col sm="10" md="10" lg="6" xl="4" class="mb-1">
          <b-input-group>
            <b-form-input id="txtSearch" v-model="filter" :placeholder="$t('components.cards.meli.settings.blocklists.user_placeholder')" minLength="3" maxLength="100" />
            <b-input-group-append>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="searchUser"
                v-b-tooltip.hover.v-primary :title="$t('components.cards.meli.settings.blocklists.tooltips.block')">
                <font-awesome-icon v-if="!processing" :icon="['fas', 'plus']" fixed-width />
                <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse fixed-width />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="clearSearch"
                v-b-tooltip.hover.v-primary :title="$t('common.tooltips.clear')">
                <font-awesome-icon :icon="['fas', 'times']" fixed-width />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="mb-1">
          <b-button-toolbar>
            <b-button-group>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="updateMassBlocklist(true)"
                v-b-tooltip.hover.v-primary :title="$t('components.cards.meli.settings.blocklists.tooltips.bulk_block')">
                <font-awesome-icon :icon="['far', 'list-alt']" />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                @click="downloadFile('xlsx')" v-b-tooltip.hover.v-primary :title="$t('common.tooltips.download')">
                <font-awesome-icon :icon="['fas', 'download']" fixed-width />
              </b-button>
              <b-button v-if="false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                @click="cardOrdersBlocklistOptions.options.loadBackground = !cardOrdersBlocklistOptions.options.loadBackground; getDetailsByIds()"
                v-b-tooltip.hover.v-primary :title="$t('common.tooltips.toggle_background')">
                <font-awesome-icon v-if="cardOrdersBlocklistOptions.options.loadBackground"
                  :icon="['fas', 'check-square']" fixed-width />
                <font-awesome-icon v-else :icon="['far', 'square']" fixed-width />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                @click="cardOrdersBlocklistOptions.options.tableStacked = !cardOrdersBlocklistOptions.options.tableStacked"
                v-b-tooltip.hover.v-primary :title="$t('common.tooltips.toggle_view')">
                <font-awesome-icon v-if="cardOrdersBlocklistOptions.options.tableStacked"
                  :icon="['fas', 'grip-horizontal']" fixed-width />
                <font-awesome-icon v-else :icon="['fas', 'grip-vertical']" fixed-width />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>

      <table-info :showLoading="formSubmitted" :showNotFound="notFound"></table-info>

      <b-row v-if="!notFound">
        <b-col cols="6" sm="6" order-sm="1" align-self="center">
          <paginator-summary v-if="false" :pagination="pagination"></paginator-summary>
        </b-col>
        <b-col cols="6" sm="6" order-sm="3">
          <b-pagination v-if="(pagination.totalRows > pagination.perPage)" v-model="pagination.currentPage"
            hide-goto-end-buttons :total-rows="pagination.totalRows" :per-page="pagination.perPage" align="right" />
        </b-col>
        <b-col v-if="false" cols="12" sm="4" align-self="center" order-sm="2" class="pb-1">
          <span v-if="cardOrdersBlocklistOptions.options.loadBackground && itemsProcessed < 100" class="text-primary small">
            <font-awesome-icon :icon="['fas', 'spinner']" pulse fixed-width /> Carregando detalhes dos usuários...
            <b-link v-b-tooltip.hover.v-primary
              :title="$t('common.tooltips.informations')">
              <font-awesome-icon :icon="['fas', 'info-circle']"
                class="text-primary" fixed-width />
            </b-link>
            <b-progress max="100">
              <b-progress-bar :value="itemsProcessed" :label="`${formatNumber(itemsProcessed, 2)}%`" />
            </b-progress>
          </span>
        </b-col>
      </b-row>

      <b-table v-if="!notFound" responsive hover :stacked="cardOrdersBlocklistOptions.options.tableStacked"
        :items="filtered" :fields="fields"
        class="mb-0">

        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.size }">
        </template>

        <template #head(index)>
          #
        </template>
        <template #cell(index)="data">
          <span v-if="cardOrdersBlocklistOptions.options.tableStacked"><strong># </strong></span>{{data.index + 1 + (pagination.perPage * (pagination.currentPage - 1))}}
        </template>

        <template #head(id)>
          {{ $t('meli.identification.id')}}
        </template>

        <template #head(nickname)>
          {{ $t('meli.identification.nickname')}}
        </template>
        <template #cell(nickname)="data">
          <span v-if="!data.item.nickname">
            {{ $t('components.cards.common.not_loaded') }}
          </span>
          <span v-if="data.item.nickname">
            <a :href="data.item.permalink" target="_blank">{{data.item.nickname}} <span v-if="data.item.points">({{data.item.points}})</span></a>
          </span>
        </template>

        <template #cell(actions)="data">
          <span class="d-block text-nowrap">
            <b-button v-if="data.item.blocked" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="danger" class="btn-rad" :disabled="data.item.processing"
              @click.stop="deleteOrderBlocklist(data.item)">
              <font-awesome-icon v-if="!data.item.processing" :icon="['fas', 'lock-open']" fixed-width />
              <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse fixed-width />
              <span> {{ $t('components.cards.meli.settings.blocklists.unblock') }}</span>
            </b-button>
            <b-button v-if="!data.item.blocked" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" class="btn-rad"
              :disabled="data.item.processing" @click.stop="addOrderBlocklist(data.item)">
              <font-awesome-icon v-if="!data.item.processing" :icon="['fas', 'lock']" fixed-width />
              <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse fixed-width />
              <span> {{ $t('components.cards.meli.settings.blocklists.block') }}</span>
            </b-button>
            </span>
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <br />
      <b-pagination v-if="(pagination.totalRows > pagination.perPage) && (y > 250)" v-model="pagination.currentPage"
        hide-goto-end-buttons :total-rows="pagination.totalRows" :per-page="pagination.perPage" align="right" />
    </b-card-body>

    <card-orders-mass-blocklist v-if="showMass" @updateMass="updateMassBlocklist"></card-orders-mass-blocklist>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BButton, BButtonToolbar, BButtonGroup,
  VBTooltip, BPagination, BProgress, BProgressBar, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import defaultConfig from '@core-md/config'
import TableInfo from '@core-md/components/TableInfo.vue'
import PaginatorSummary from '@core-md/components/PaginatorSummary.vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import stringHelper from '@core-md/mixins/stringHelper'
import dateHelper from '@core-md/mixins/dateHelper'
import Excel from '@core-md/utils/excel'
import { $themeConfig } from '@themeConfig'
import { useWindowScroll } from '@vueuse/core'
import CardOrdersMassBlocklist from './CardOrdersMassBlocklist.vue'
import cardOrdersBlocklistOptions from './_cardOrdersBlocklistOptions'
import simpleAccountSelector from '../../../components/SimpleAccountSelector.vue'
import meliUsers from '@/services/meli/users'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BPagination,
    BProgress,
    BProgressBar,
    BLink,
    simpleAccountSelector,
    TableInfo,
    PaginatorSummary,
    CardOrdersMassBlocklist,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
    stringHelper,
    dateHelper,
  ],
  data() {
    return {
      selectedAccount: null,
      cardOrdersBlocklistOptions,
      fields: [
        {
          key: 'index',
          label: '',
          size: '40px',
          tdClass: 'pl-0 pl-sm-1 text-left',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'id',
          label: '',
          sortable: true,
          size: '80px',
          tdClass: 'pl-0 pl-sm-1',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'nickname',
          label: '',
          sortable: true,
          size: '220px',
          tdClass: 'pl-0 pl-sm-1',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'actions',
          label: '',
          size: '140px',
          tdClass: 'pl-0 pl-sm-1 text-center text-sm-left',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
      ],
      results: [],
      filtered: [],
      pagination: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        sortBy: 'id',
        sortDesc: false,
      },
      userIds: [],
      filter: '',
      showMass: false,
      formSubmitted: false,
      processing: false,
      unWatchSelectedAccount: null,
    }
  },
  computed: {
    accountLoaded() {
      return this.selectedAccount.loading >= 100
    },
    notFound() {
      return this.pagination.totalRows === 0
    },
    usersPublic() {
      return this.$store.state.meli.users
    },
    itemsProcessed() {
      return ((this.results.length - this.userIds.length) / this.results.length) * 100
    },
    currentPage() {
      return this.pagination.currentPage
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    const { y } = useWindowScroll()

    return {
      y,
      spinnerType,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
          this.getData()
        }
      })
    }
    this.$store.dispatch('meli/initialize')
    this.getData()
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
  methods: {
    getData(page = 1) {
      if (this.selectedAccount) {
        this.results = []
        this.pagination.totalRows = 0
        this.pagination.currentPage = page
        this.formSubmitted = true
        const request = {
          accessToken: this.selectedAccount.access_token,
          sellerId: this.selectedAccount.seller_id,
          limit: this.pagination.perPage,
          offset: (page - 1) * this.pagination.perPage,
        }
        meliUsers.getOrdersBlocklist(request).then(response => {
          if (response.httpCode === 200) {
            this.userIds = []

            response.body.forEach(t => {
              let userDetail = this.usersPublic.find(user => user.id === t.user.id)
              if (!userDetail) {
                userDetail = {
                  id: t.user.id,
                  nickname: this.$i18n.t('components.cards.common.not_loaded'),
                  permalink: '',
                  points: 0,
                }
                this.userIds.push(userDetail.id)
              }
              userDetail.processing = false
              userDetail.blocked = true

              this.results.push(userDetail)
            })

            if (response.body.length < request.limit) {
              this.pagination.totalRows = request.offset + response.body.length
            } else {
              this.pagination.totalRows = request.offset + request.limit + 1 // response.body.paging.total // TODO: alterar
            }
            if (this.cardOrdersBlocklistOptions.options.loadBackground) {
              this.getDetailsByIds()
            }
          }
          this.formSubmitted = false
          this.filtered = this.results
        }).catch(data => {
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    getDetailsByIds() {
      if (this.cardOrdersBlocklistOptions.options.loadBackground && this.userIds.length > 0) {
        const start = 0
        const end = defaultConfig.MELI_MULTIGET_QTY
        const itemToProcess = this.userIds.slice(start, end)
        this.userIds = this.userIds.slice(end)
        const params = {
          accessToken: this.selectedAccount.access_token,
          ids: itemToProcess,
        }
        this.$store.dispatch('meli/refreshUserDetails', params).then(response => {
          this.updateUsers(response)

          setTimeout(() => {
            this.getDetailsByIds()
          }, 2000)
        })
      }
    },
    updateUsers(users) {
      const newResults = []

      this.results.forEach(user => {
        let updatedUser = users.find(result => result.id === user.id)
        if (updatedUser) {
          updatedUser.processing = false
          updatedUser.blocked = true
        } else {
          updatedUser = user
        }
        newResults.push(updatedUser)
      })
      this.results = newResults
      if (!this.filter) {
        this.filtered = this.results
      }
      console.log(`Carregando dados de usuários: ${this.formatNumber(this.itemsProcessed, 2)}%`)
    },
    changeSelectedAccount(selected) {
      this.selectedAccount = selected
      this.getData()
    },
    clearSearch() {
      this.filter = ''
      this.filtered = this.results
      // this.pagination.totalRows = this.results.length
    },
    searchUser() {
      if (this.filter && this.filter.length >= 3) {
        this.processing = true
        meliUsers.searchUser(this.selectedAccount.access_token, this.filter).then(response => {
          if (response.httpCode === 200 && response.body.id) {
            meliUsers.searchUserOrderBlocklist(this.selectedAccount.access_token, this.selectedAccount.seller_id, response.body.id).then(responseBlocked => {
              if (responseBlocked.httpCode === 200 && responseBlocked.body.user && responseBlocked.body.user.blocked === true) {
                const found = response.body
                found.blocked = true
                this.filtered = [found]
                this.pagination.currentPage = 1
                this.pagination.totalRows = this.filtered.length
                this.$root.showMessageInfo(this.$i18n.t('components.cards.meli.settings.blocklists.messages.already_blocked'), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon')
                this.processing = false
              } else {
                this.addOrderBlocklist(response.body)
              }
            })
          } else {
            this.$root.showMessageError(this.$i18n.t('components.cards.meli.settings.blocklists.messages.user_not_found'), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon')
            this.processing = false
          }
        })
      } else {
        this.$root.showMessageError(this.$i18n.t('components.cards.meli.settings.blocklists.messages.min_length'), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon')
      }
    },
    addOrderBlocklist(userItem) {
      userItem.processing = true // eslint-disable-line
      meliUsers.addToOrderBlocklist(this.selectedAccount.access_token, this.selectedAccount.seller_id, userItem.id).then(response => {
        if (response.httpCode === 200 || response.httpCode === 201) {
          this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.settings.blocklists.messages.success_block', { user: userItem.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon') // eslint-disable-line prefer-template
          userItem.blocked = true // eslint-disable-line
          this.results.push(userItem)
          this.filtered = [userItem]
          this.pagination.currentPage = 1
          this.pagination.totalRows += 1
        } else {
          this.$root.showMessageError(this.$i18n.t('components.cards.meli.settings.blocklists.messages.error_block', { user: userItem.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon')
        }
        userItem.processing = false // eslint-disable-line
        this.processing = false
      })
    },
    deleteOrderBlocklist(userItem) {
      userItem.processing = true // eslint-disable-line
      // userItem._rowVariant = 'info' // eslint-disable-line
      meliUsers.deleteUserFromOrdersBlocklist(this.selectedAccount.access_token, this.selectedAccount.seller_id, userItem.id).then(response => {
        if (response.httpCode === 200) {
          this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.settings.blocklists.messages.success_unblock', { user: userItem.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_unblock'), 'UnlockIcon') // eslint-disable-line prefer-template
          userItem.blocked = false // eslint-disable-line
          userItem._rowVariant = 'success' // eslint-disable-line
          userItem.processing = false // eslint-disable-line
          setTimeout(() => {
            this.removeItem(userItem)
          }, 1000, userItem)
        } else {
          this.$root.showMessageError(this.$i18n.t('components.cards.meli.settings.blocklists.messages.error_unblock', { user: userItem.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_unblock'), 'UnlockIcon')
          userItem._rowVariant = 'danger' // eslint-disable-line
          userItem.processing = false // eslint-disable-line
        }
      })
    },
    removeItem(userItem) {
      const newResults = []

      this.results.forEach(user => {
        if (user.id !== userItem.id) {
          newResults.push(user)
        }
      })
      this.results = newResults
      this.pagination.totalRows -= 1
      this.clearSearch()
    },
    downloadFile(fileFormat) {
      const data = {
        worksheetName: this.$i18n.t('components.cards.meli.settings.blocklists.orders_sheet_name'),
        fileName: this.$i18n.t('components.cards.meli.settings.blocklists.orders_file_name') + fileFormat, // eslint-disable-line
        format: fileFormat,
      }
      const fontStyle = {
        name: 'Calibri', size: 11,
      }
      data.columns = [
        { header: this.$i18n.t('meli.identification.id'), width: 15, style: { font: fontStyle } },
        { header: this.$i18n.t('meli.identification.nickname'), width: 65, style: { font: fontStyle } },
      ]
      data.rows = this.results.map(t => {
        const r = [
          t.id,
          t.nickname,
        ]
        return r
      })

      Excel.downloadFile(data)
    },
    updateMassBlocklist(_showMass) {
      if (!_showMass) {
        this.getData(1)
      }
      this.showMass = _showMass
    },
  },
  watch: {
    currentPage(page) {
      this.getData(page)
    },
  },
}
</script>
